.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  /* background: #3da3d5; */
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgb(53, 53, 53);
}


.timeline-title {
  color: white ;
  font-size: 2.3em ;
  font-weight: 500 ;
  padding-top: 10px ;
  /* font-size: 15em; */
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  background-color: whitesmoke;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.50),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) ;
  padding: 2em 3em !important;
  text-align: left;
}

.date {
  background-color: white;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.50),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
 
  color: white;
  text-align: center !important;
}

#description {
  margin: 1.5em 0 2em 0;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: #06d6a0;
}

.workButton:hover {
  background-color: #0ac593;
}

.schoolButton {
  background-color: #f9c74f;
}

.schoolButton:hover {
  background-color: #f3bc3c;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}
.no-bullet {
  list-style: none;
}

/* Apply indentation */
.indent {
  display: inline-block;
  width: 1em; /* Adjust the width to control the level of indentation */
  white-space: pre;
}